// Ref: https://nts.strzibny.name/button-loader-turbo-forms/

import { Controller } from "@hotwired/stimulus";

const SPINNER_HTML = `
<span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
<span class="visually-hidden" role="status">Loading...</span>
`.trim();

// Connects to data-controller="lazy-form"
export default class extends Controller {
  static targets = ["button"];

  submit(e) {
    const button = this.buttonTarget;

    button.style.width = `${button.offsetWidth}px`;
    button.style.height = `${button.offsetHeight}px`;

    button.innerHTML = SPINNER_HTML;
    button.disabled = true;
  }
}
