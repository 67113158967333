const applyScroll = (element) => {
  const scrollPosition = window.scrollY || document.documentElement.scrollTop;
  const scrollLink = document.querySelector(".scroll-to-top");

  if (scrollPosition > 1) {
    element.classList.add("fixed-header", "animated", "slideInDown");
    if (scrollLink) {
      scrollLink.style.opacity = "1";
      scrollLink.style.visibility = "visible";
    }
  } else {
    element.classList.remove("fixed-header", "animated", "slideInDown");
    if (scrollLink) {
      scrollLink.style.opacity = "0";
      scrollLink.style.visibility = "hidden";
    }
  }
};

export const headerScrollHandler = () => {
  const elements = document.querySelectorAll(".main-header, #flash-message");

  for (const el of elements) {
    applyScroll(el);
  }
};

headerScrollHandler();

window.addEventListener("scroll", () => {
  headerScrollHandler();
});
