import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message"];

  connect() {
    console.log("Flash message connected");
    setTimeout(() => this.#hide(), 3000);
    setTimeout(() => this.#remove(), 5000);
  }

  close() {
    this.element.remove();
  }

  #remove() {
    for (const messageEl of this.messageTargets) {
      messageEl.remove();
    }
  }

  #hide() {
    for (const messageEl of this.messageTargets) {
      messageEl.classList.remove("animate__fadeInDown");
    }
  }
}
