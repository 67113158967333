import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["phone", "email", "toggle"];
  static values = { currentType: String };
  static outlets = ["phone-input"];

  connect() {
    this.#initializeFormType();
    this.#setInputsVisibility();

  }

  select({ target: { value } }) {
    this.currentTypeValue = value;
    this.#setInputsVisibility();
  }

  #initializeFormType() {
    for (const targetEl of this.toggleTargets) {
      if (targetEl.value === this.currentTypeValue) {
        targetEl.checked = true;
        targetEl.click();
        break;
      }
    }
  }

  #setInputsVisibility() {
    this.#updateElementVisibility(this.phoneTarget, this.#isPhoneNumber);
    this.#updateElementVisibility(this.emailTarget, !this.#isPhoneNumber);

    if (this.#isPhoneNumber) {
      this.phoneInputOutlet.setDisabled(false);
    }
  }

  #updateElementVisibility(element, visible) {
    const classListMethod = visible ? "remove" : "add";

    for (const el of [element, element.closest(".form-group")]) {
      el.classList[classListMethod]("d-none");
    }
    element.disabled = !visible;
  }

  get #isPhoneNumber() {
    return this.currentTypeValue === "phone_number";
  }
}
